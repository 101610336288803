import { disableCache } from '@iconify/react'
import ReactDOM from 'react-dom/client'
import AppFactory from './factory/AppFactory'
import reportWebVitals from './reportWebVitals'

reportWebVitals()
// Disable iconify caching in LocalStorage
disableCache('local')

let data

if (typeof window !== 'undefined') {
  data = (window as any).__data__
}

const appElement = document.getElementById('root')
if (appElement) {
  ReactDOM.hydrateRoot(appElement, <AppFactory data={data} />)
}
